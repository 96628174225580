<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :search="search"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Mesin</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" v-if="$store.state.currentPagePriv.create">
                      New Machine
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <span>Code</span>
                            <v-text-field v-model="editedItem.code" filled rounded dense hide-details="auto" @keydown.space.prevent :readonly="editedIndex>-1" :rules="reqRule"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <span>Machine Name</span>
                            <v-text-field v-model="editedItem.description" filled rounded dense hide-details="auto" :rules="reqRule"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <span>Capacity</span>
                            <v-text-field v-model="editedItem.misc_field" filled rounded dense suffix="ml" hide-details="auto"></v-text-field>
                        </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="body-1 text-center">Anda akan menghapus mesin ini?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)" v-if="$store.state.currentPagePriv.update">
                mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" v-if="$store.state.currentPagePriv.delete">
                mdi-delete
            </v-icon>
            </template>
            <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Code', value: 'code' },
        { text: 'Machine Name', value: 'description' },
        { text: 'Capacity', value: 'misc_field' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      search: '',
      reqRule: [v => !!v || 'Harus diisi!'],
      valid: true,
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Machine' : 'Edit Machine'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        let response = await this.$store.dispatch('misccode/getObjs')
        if (response.data.status=='success') {
          this.items = response.data.data.filter(el => { return el.type=='Mesin'})
        }
        console.log(this.items)
      },

      editItem (item) {
        console.log(item)
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        let url = this.$store.state.config.apiHost + "miscCodes/" + this.editedItem.code
        let response = await this.$axios.delete(url, {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
        if (response.data.status=='success') this.items.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        await this.$refs.form.validate();
        if (!this.valid) return;

        if (this.editedIndex > -1) {
          let url = this.$store.state.config.apiHost + "miscCodes/" + this.editedItem.code
          let response = await this.$axios.put(url, JSON.stringify(this.editedItem), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
          if (response.data.status=='success') Object.assign(this.items[this.editedIndex], this.editedItem)

          
        } else {
          try {
            this.$set(this.editedItem, 'type', 'Mesin')
            console.log(this.editedItem)
            let url = this.$store.state.config.apiHost + "miscCodes"
            let response = await this.$axios.post(url, JSON.stringify(this.editedItem), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
            if (response.data.status=='success') this.items.push(this.editedItem)
          }
          catch (err) {
            console.log(err)
            const keyword = 'duplicate key'
            if (err.response.data.message.search(keyword) > -1){
              return this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Kode sudah terpakai."})
            }
            else {
              return this.$store.commit('setSnackbar', {saveError: true, errorMessage: err.response.data.message})
            }
          }
        }
        this.close()
      },
    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>